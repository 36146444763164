import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {Playlist} from "./domain/model/Playlist";
import {ServerError} from "./ServerError";

export default class Backend {
    private axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: 'https://new-releases-playlist.pernpas.de',
        });
        this.axiosInstance.interceptors.request.use(
            async config => {
                let token = localStorage.getItem('token');
                if (!token) {
                    console.log('No token found');
                }
                config.headers.Authorization = `Bearer ${token}`;
                return config;
            }
        );
    }


    async refreshToken(): Promise<void> {
        try {
            await this.axiosInstance.get('/refresh-token');
        } catch (error) {
            console.error('Error refreshing token:', error);
            throw error;
        }
    }

    async createPlaylist(name: string): Promise<Playlist[]> {
        try {
            const response: AxiosResponse = await this.axiosInstance.post('/playlist', {name});
            return response.data;
        } catch (error) {
            console.error('Error creating playlist:', error);
            throw error;
        }
    }

    async updateAllPlaylistsWithNewSongs(): Promise<void> {
        try {
            await this.axiosInstance.post('/playlist-command');
        } catch (error: any) {
            console.error('Error updating playlists with new songs:', error.response.data.message);
            throw new ServerError(error.response.data.message, error.response.data.error);
        }
    }

    async updateArtistInPlaylist(playlistId: string, artistId: string, deactivated: boolean): Promise<any> {
        try {
            const response: AxiosResponse = await this.axiosInstance.patch(`/playlist/${playlistId}/artist/${artistId}`, {deactivated});
            return response.data;
        } catch (error) {
            console.error('Error updating artist in playlist:', error);
            throw error;
        }
    }

    async importFollowedArtists(): Promise<any> {
        try {
            const response: AxiosResponse = await this.axiosInstance.post('/import-followed-artists');
            return response.data;
        } catch (error) {
            console.error('Error importing followed artists:', error);
            throw error;
        }
    }

    async getPlaylists(): Promise<any> {
        try {
            const response: AxiosResponse = await this.axiosInstance.get('/playlist');
            return response.data;
        } catch (error) {
            console.error('Error getting playlists:', error);
            throw error;
        }
    }

    async getPlaylistArtists(playlistId: string): Promise<any> {
        try {
            const response: AxiosResponse = await this.axiosInstance.get(`/playlist/${playlistId}/artist`);
            return response.data;
        } catch (error) {
            console.error('Error getting playlist artists:', error);
            throw error;
        }
    }

    async addArtistToPlaylist(playlistId: string, artistName: string): Promise<any> {
        try {
            const response: AxiosResponse = await this.axiosInstance.post(`/playlist/${playlistId}/artist`, {artistName});
            return response.data;
        } catch (error) {
            console.error('Error adding artist to playlist:', error);
            throw error;
        }
    }

    async addArtistsToPlaylist(playlistId: string, artistNames: string[]): Promise<any> {
        try {
            const chunkSize = 500;
            const chunks = [];
            for (let i = 0; i < artistNames.length; i += chunkSize) {
                chunks.push(artistNames.slice(i, i + chunkSize));
            }

            const data = [];
            for (const chunk of chunks) {
                const response: AxiosResponse = await this.axiosInstance.post(`/playlist/${playlistId}/artists`, {artistNames: chunk});
                data.push(response.data);
            }
            return data;

        } catch (error) {
            console.error('Error adding artist to playlist:', error);
            throw error;
        }
    }

    async removeArtistFromPlaylist(playlistId: string, artistId: string): Promise<any> {
        try {
            const response: AxiosResponse = await this.axiosInstance.delete(`/playlist/${playlistId}/artist/${artistId}`);
            return response.data;
        } catch (error) {
            console.error('Error removing artist from playlist:', error);
            throw error;
        }
    }
}
