import React from 'react';
import {Playlist} from "../domain/model/Playlist";
import Backend from "../Backend";
import {Button, CircularProgress, Container, Grid, MenuItem, TextField, Typography} from "@mui/material";

const backend = new Backend();

export function AddArtistsToPlaylist() {

    const [artists, setArtists] = React.useState<string[]>([]);
    const [playlists, setPlaylists] = React.useState<Playlist[]>([]);
    const [selectedPlaylist, setSelectedPlaylist] = React.useState<Playlist>();
    const [progress, setProgress] = React.useState<number>(0);
    const [result, setResult] = React.useState<string>('');


    React.useEffect(() => {
        backend.getPlaylists().then((playlists) => {
            setPlaylists(playlists);
        });
    }, []);


    function addSelectedArtistsToSelectedPlaylist() {
        setProgress(1);
        if (selectedPlaylist) {
            backend.addArtistsToPlaylist(selectedPlaylist.spotifyId, artists).then((result) => {
                setResult(JSON.stringify(result));
                setProgress(0);
            }).catch((error) => {
                setResult(JSON.stringify(error));
                setProgress(0);
            });
        } else {
            setResult("No playlist selected");
            setProgress(0);
        }
    }

    return (
        <Container maxWidth="md">
            <Typography variant="h1">Add artists to playlist</Typography>
            {playlists.length > 0 && <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    <TextField
                        id={"artists"}
                        label={"Paste artists here"}
                        multiline
                        rows={5}
                        fullWidth={true}
                        onChange={(event) => setArtists(event.target.value.split("\n").map(a => a.replaceAll('"', "")))}/>
                </Grid>
                {/*the component should contain a dropdown where the user can select a playlist from playlists*/}
                <Grid item>
                    <TextField
                        id={"playlists"}
                        label={"Select playlist"}
                        select
                        value={selectedPlaylist}
                        onChange={(event) => setSelectedPlaylist(playlists.find((playlist) => playlist.spotifyId === event.target.value))}
                    >
                        {playlists.map((playlist) => (
                            <MenuItem key={playlist.spotifyId} value={playlist.spotifyId}>{playlist.name}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={() => addSelectedArtistsToSelectedPlaylist()}>Add artists to
                        playlist</Button>
                </Grid>
                <Grid item>
                    {progress > 0 && <CircularProgress/>}
                </Grid>
            </Grid>}

            {result.length > 0 && <Typography variant="body1">{result}</Typography>}

        </Container>
    );
}