import React, {FC} from 'react';
import Backend from "../Backend";
import {Button, Container, List, ListItem, Typography} from "@mui/material";
import {ServerError} from "../ServerError";

const backend = new Backend();

export const Commands: FC = () => {

    const [feedback, setFeedback] = React.useState<string>('');

    function importFollowedArtists() {
        setFeedback("processing...");
        backend.importFollowedArtists()
            .then(() => {
                setFeedback("Imported followed artists successfully");
            })
            .catch(() => {
                setFeedback("Error while importing followed artists");
            });
    }

    function updateAllPlaylistsWithNewSongs() {
        setFeedback("processing...");
        backend.updateAllPlaylistsWithNewSongs()
            .then(() => {
                setFeedback("Updated all playlists successfully");
            })
            .catch((e: ServerError) => {
                setFeedback("Error while updating all playlists! " + e);
            });
    }

    return (
        <Container>
            <Typography variant="h1">Commands</Typography>
            <List>
                <ListItem><Button onClick={() => importFollowedArtists()}>Import followed artists</Button></ListItem>
                <ListItem><Button onClick={() => updateAllPlaylistsWithNewSongs()}>Update all playlists with new
                    songs</Button></ListItem>
            </List>
            <Typography variant="body1">{feedback}</Typography>
        </Container>
    );
}