// generate an About page from a template:
import React, {FC, ReactElement} from "react";
import Backend from "../Backend";
import {Playlist} from "../domain/model/Playlist";
import {Link} from "react-router-dom";
import {Container, List, ListItem, Typography} from "@mui/material";

const backed = new Backend();

const Playlists: FC<any> = (): ReactElement => {

    const [playlists, setPlaylists] = React.useState<Playlist[]>([]);

    React.useEffect(() => {
        backed.getPlaylists().then((playlists) => {
            setPlaylists(playlists);
        });
    }, []);


    return (
        <Container>
            <Typography variant="h1">Playlists</Typography>
            <List>
                {playlists.map((playlist) => (
                    <ListItem key={playlist.spotifyId}><Link to={playlist.spotifyId}>{playlist.name}</Link></ListItem>
                ))}
            </List>
        </Container>
    );
};

export default Playlists;
