export class ServerError extends Error {

    constructor(message: string, name: string) {
        super(message);
        this.name = name;
    }

    toString(): string {
        return `${this.name}: ${this.message}`;
    }

}