import React, {FC, ReactElement, useEffect} from "react";
import {Box, TextField} from "@mui/material";

const Home: FC<any> = (): ReactElement => {
    const [token, setToken] = React.useState<string>('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        setToken(token || 'no token found');
    }, []);


    return (
        <Box sx={{
            flexGrow: 1,
            backgroundColor: 'whitesmoke',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <Box>
                <TextField
                    id="outlined-multiline-flexible"
                    label="Enter token"
                    multiline
                    maxRows={4}
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    onBlur={(e) => {
                        setToken(e.target.value);
                        localStorage.setItem('token', e.target.value)
                    }}
                />
            </Box>
        </Box>
    );
};

export default Home;