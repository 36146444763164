// pages
import Home from "./pages/Home";
import Playlists from "./pages/Playlists";

// other
import {FC} from "react";
import PlaylistDetails from "./pages/PlaylistDetails";
import {Commands} from "./pages/Commands";
import {AddArtistsToPlaylist} from "./pages/AddArtistsToPlaylist";

// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    showInMenu: boolean,
    component: FC<{}>
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        title: 'Home',
        path: '/',
        enabled: true,
        showInMenu: true,
        component: Home
    },
    {
        key: 'playlists-route',
        title: 'Playlists',
        path: '/playlists',
        enabled: true,
        showInMenu: true,
        component: Playlists
    },
    {
        key: 'playlist-details-route',
        title: 'Playlists',
        path: '/playlists/:id',
        enabled: true,
        showInMenu: false,
        component: PlaylistDetails
    },
    {
        key: 'commands-route',
        title: 'Commands',
        path: '/commands',
        enabled: true,
        showInMenu: true,
        component: Commands
    },
    {
        key: 'add-artists-to-playlist-route',
        title: 'Add Artists To Playlist',
        path: '/add-artists-to-playlist',
        enabled: true,
        showInMenu: true,
        component: AddArtistsToPlaylist
    }
]