import * as React from 'react';
import {FC, useEffect} from 'react';
import {useParams} from "react-router-dom";
import Backend from "../Backend";
import {Artist} from "../domain/model/Artist";
import {
    Button,
    CircularProgress,
    Container,
    FormControlLabel,
    FormGroup, List, ListItem,
    Switch,
    TextField,
    Typography
} from "@mui/material";

const backend = new Backend();

function ArtistDetails(props: { artist: Artist, playlistId: string }) {

    const [artist, setArtist] = React.useState(props.artist);
    const [error, setError] = React.useState<string>('');

    function handleChange() {
        setArtist(prevState => {
            backend.updateArtistInPlaylist(props.playlistId, prevState.spotifyId, !prevState.deactivated)
                .catch(() => {
                    setError("Error while updating");
                })
            return {
                ...prevState,
                deactivated: !prevState.deactivated
            }
        });
    }

    return <FormGroup>
        <ListItem>
            <FormControlLabel
                id={artist.spotifyId}
                checked={!artist.deactivated}
                control={<Switch/>}
                onChange={() => handleChange()}
                label={artist.deactivated ? "deactivated" : "active"}/> {artist.name} {error}</ListItem>
    </FormGroup>;
}

const PlaylistDetails: FC = () => {

    const [artists, setArtists] = React.useState<Artist[]>([]);
    const [addArtist, setAddArtist] = React.useState<string>('');

    const {id} = useParams();

    useEffect(() => {
        backend.getPlaylistArtists(id!).then((artists) => {
            setArtists(artists);
        });
    }, [id]);

    const addArtistToPlaylist = () => {
        backend.addArtistToPlaylist(id!, addArtist)
            .then((artist) => {
                setArtists(prevState => {
                    return [...prevState, artist];
                });
                setAddArtist('');
            })
            .catch(reason => {
                console.log(reason);
            })
    };

    return <Container>
        <Typography variant="h1">Playlist Details</Typography>
        {artists.length === 0 && <Container><CircularProgress/></Container>}
        {artists.length > 0 &&
            <Container>
                <Container>
                    <Typography variant="body1">Playlist id: {JSON.stringify(id)}</Typography>
                    <Typography variant="body1" gutterBottom>Total artists
                        active: {artists.filter(a => !a.deactivated).length}</Typography>
                    <Typography variant="body1" gutterBottom>Total artists
                        deactivated: {artists.filter(a => a.deactivated).length}</Typography>
                </Container>
                <Container>
                    <TextField label="Add artist" value={addArtist} onChange={(e) => setAddArtist(e.target.value)}/>
                    <Button onClick={() => addArtistToPlaylist()}>Add</Button></Container>
                <List>
                    {artists.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map((artist: Artist) => <ArtistDetails key={artist.spotifyId} playlistId={id!}
                                                                artist={artist}/>)}
                </List>
            </Container>}
    </Container>

}

export default PlaylistDetails;